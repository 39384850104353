@import 'sass/styles';

.footerContainer {
  background: $gunmetal;

  @media (min-width: $minDesktopWidth) {
    @include flexAlign(center, center);
    flex-direction: column;
    width: 100%;
    padding: 60px 56px 32px 56px;
    gap: 16px;
  }
}

.topSection {
  &Wrapper {
    width: 100%;
    @include flexAlign(center, center);
    flex-direction: column;
    gap: 60px;

    @media (max-width: $maxSmallScreenWidth) {
      > hr {
        display: none;
      }
    }

    @media (min-width: $minMobileWidth) and (max-width: $maxMobileWidth) {
      > hr {
        display: none;
      }
    }
  }

  &Container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    justify-content: space-between;

    @media (max-width: $maxMobileWidth) {
      padding: 40px 16px;
      flex-direction: column;
    }

    @media (min-width: $minMobileWidth) and (max-width: $maxMobileWidth) {
      padding: 40px 56px;
      flex-direction: column;
    }
  }

  &ColumnSeparator {
    flex: 1;

    @media (min-width: 1440px) {
      flex: 2;
    }

    @media (min-width: 1792px) {
      flex: 3;
    }
  }
}

.copyright {
  &Wrapper {
    width: 100%;
    @include flexAlign(center, center);
    flex-direction: column;
    gap: 16px;

    @media (max-width: $maxSmallScreenWidth) {
      align-items: flex-start;
      padding: 0px 16px 40px 16px;
    }

    @media (min-width: $minMobileWidth) and (max-width: $maxMobileWidth) {
      align-items: flex-start;
      padding: 0px 56px 40px 56px;
    }
  }

  &Box {
    width: 100%;
    @include flexAlign(center, center);
    flex-direction: column;
    gap: 16px;
    max-width: 1328px;
  }

  &Recaptcha {
    display: flex;

    @media (max-width: $maxMobileWidth) {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    @media (min-width: 920px) and (max-width: $maxMobileWidth) {
      flex-direction: row;
      justify-content: flex-start;
      gap: 48px;
    }

    @media (min-width: $minDesktopWidth) {
      @include flexAlign(center, center);
      flex-direction: row;
      gap: 32px;
      align-self: stretch;
    }

    > p,
    > span {
      color: $cream;
    }

    &Link {
      display: inline;
      text-decoration: underline;
      color: $cream !important;
      font-weight: 300 !important;

      &:visted {
        color: $cream;
        text-decoration: underline;
      }

      &:link {
        color: $cream;
        text-decoration: underline;
      }
    }
  }

  &ItemsContainer {
    @include flexAlign(center, center);
    flex-direction: column;
    gap: 8px;
    align-self: stretch;

    > span {
      color: $cream;
    }
  }

  &LinksBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    @media (max-width: $maxMobileWidth) {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }

    > a,
    > span {
      color: $cream;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        @extend %typographyTiny;
        color: $cream;
        text-decoration: underline;
      }

      &:visted {
        color: $cream;
      }

      &:link {
        color: $cream;
        @extend %typographyTiny;
      }

      @media (max-width: $maxMobileWidth) {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.mobileFooterLinks {
  display: none;

  @media (max-width: $maxMobileWidth) {
    display: flex;
    padding: 0px 16px 24px 16px;
  }

  @media (min-width: $minMobileWidth) and (max-width: $maxMobileWidth) {
    display: flex;
    padding: 0px 56px 24px 56px;
  }
}

.topCategories {
  &Wrapper {
    background: $gunmetal;
    padding: 36px 56px;

    @media (max-width: $maxMobileWidth) {
      flex-direction: column;
      padding: 16px;
    }

    @media (min-width: $minMobileWidth) and (max-width: $maxMobileWidth) {
      flex-direction: column;
      padding: 16px 56px;
    }
  }

  &Container {
    @include flexAlign(center, center);
    gap: 32px;

    > span {
      @extend %typographyH6;
      color: $cream;
      white-space: nowrap;
    }

    @media (max-width: $maxMobileWidth) {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
  }

  &Links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: $maxMobileWidth) {
      width: 100%;
      gap: 24px;
    }

    > a {
      @extend %typographySmall;
      color: $cream;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        @extend %typographySmall;
        color: $cream;
        text-decoration: underline;
      }

      &:visted {
        color: $cream;
      }

      &:link {
        color: $cream;
        @extend %typographySmall;
      }

      @media (max-width: $maxMobileWidth) {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

// There is a strange thing adding a bottom margin in the body and fixing this height in the footer fix it
.footerComponent {
  height: 400px;
}
