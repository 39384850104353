@import 'sass/styles';

.countryFlagBox {
  cursor: pointer;

  > div {
    width: 30px;
    height: 30px;

    @media (max-width: $maxMobileWidth) {
      width: 20px;
      height: 20px;
    }
  }
}
