@import 'sass/styles';

.icons {
  height: 100%;
  @include flexAlign(center, center);
  flex-direction: row;
  gap: 16px;

  > a {
    display: flex;
  }
}

.searchIconContainer {
  display: none;

  > svg {
    width: 30px;
    height: 30px;
    @media (max-width: $maxMobileWidth) {
      width: 24px;
      height: 24px;
    }

    > path {
      fill: $gunmetal;
    }
  }

  @media (max-width: $maxMobileWidth) {
    display: flex;
  }
}

.mobileHiddenIconContainer {
  display: flex;

  > a {
    display: flex;
  }

  @media (max-width: $maxMobileWidth) {
    display: none;
  }
}

.hamburgerBox {
  display: none;

  @media (max-width: $maxMobileWidth) {
    @include flexAlign(center, center);
    width: 24px;
    height: 24px;
  }
}

.closeHamburgerIcon {
  width: 16px;
  height: 16px;
}

.hamburguerIcon {
  width: 24px;
  height: 24px;
}
