@import 'sass/styles';

.alert {
  &Container {
    padding: 12px;
    background-color: $almostWhite;
    width: 100%;
    border-radius: 4px;

    @media (max-width: $maxMobileWidth) {
      padding: 6px 8px;
      border-radius: 6px;
    }

    &NoBorder {
      border-radius: 0px;

      @media (max-width: $maxMobileWidth) {
        border-radius: 0px;
      }
    }
  }

  &Box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    @media (max-width: $maxMobileWidth) {
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  &Text {
    @include typographySmall;

    @media (max-width: $maxMobileWidth) {
      flex: 1;
    }
  }

  &Cta {
    @include typographyCtaMedium;
    text-decoration: underline;
    text-transform: uppercase;
    cursor: pointer;
    margin-left: 16px;

    @media (max-width: $maxMobileWidth) {
      font-weight: 500;
      font-size: 0.75rem; /* 12px */
      line-height: 1.125rem; /* 18px */
      letter-spacing: 0.5px;
      margin-top: 4px;
      margin-left: 28px;
      order: 5;
      flex: 1;
      flex-basis: 100%;
    }
  }
}

.iconBox {
  width: 24px;
  height: 24px;

  @media (max-width: $maxMobileWidth) {
    width: 18px;
    height: 18px;
    flex: 0;
    padding-top: 2px;
  }

  > svg {
    width: 24px;
    height: 24px;

    @media (max-width: $maxMobileWidth) {
      width: 18px;
      height: 18px;
    }
  }

  &Warning {
    width: 28px;
    height: 24px;
    align-self: flex-start;

    @media (max-width: $maxMobileWidth) {
      width: 21px;
      height: 18px;
      flex: 0;
    }

    > svg {
      width: 28px;
      height: 24px;

      @media (max-width: $maxMobileWidth) {
        width: 21px;
        height: 18px;
      }
    }
  }
}

.closeIcon {
  position: absolute;
  right: 10px;
  width: 16px;
  height: 16px;
  cursor: pointer;

  > svg {
    width: 16px;
    height: 16px;
    stroke-width: 2px;
  }

  @media (max-width: $maxMobileWidth) {
    position: unset;
    right: unset;
    flex: 0;
    display: flex;
    padding-top: 6px;

    > svg {
      width: 11px;
      height: 11px;
      stroke-width: 2px;
    }
  }
}

.errorAlert {
  background-color: $red20;
}

.infoAlert {
  background-color: $blue;
}

.noticeAlert {
  background-color: $almostWhite;
}

.successAlert {
  background-color: $green;
}

.warningAlert {
  background-color: $yellow;
}
