%elevationBottomXS {
  box-shadow: 0px 1px 3px 0px rgba(17, 17, 17, 0.1), 0px 1px 2px 0px rgba(17, 17, 17, 0.06);
}

%elevationBottomSmall {
  box-shadow: 0px 4px 6px -1px rgba(17, 17, 17, 0.1), 0px 2px 4px -1px rgba(17, 17, 17, 0.06);
}

%elevationBottomMedium {
  box-shadow: 0px 10px 15px -3px rgba(17, 17, 17, 0.1), 0px 4px 6px -2px rgba(17, 17, 17, 0.05);
}

%elevationBottomLarge {
  box-shadow: 0px 15px 40px -4px rgba(0, 0, 0, 0.14), 0px 4px 8px -2px rgba(17, 17, 17, 0.05);
}

%elevationTopXS {
  box-shadow: 0px -1px 3px 0px rgba(17, 17, 17, 0.1), 0px -1px 2px 0px rgba(17, 17, 17, 0.06);
}

%elevationTopSmall {
  box-shadow: 0px -4px 6px -1px rgba(17, 17, 17, 0.1), 0px -2px 4px -1px rgba(17, 17, 17, 0.06);
}

%elevationTopMedium {
  box-shadow: 0px -10px 15px -3px rgba(17, 17, 17, 0.1), 0px -4px 6px -2px rgba(17, 17, 17, 0.05);
}

%elevationTopLarge {
  box-shadow: 0px -15px 40px -4px rgba(0, 0, 0, 0.14), 0px -4px 8px -2px rgba(17, 17, 17, 0.05);
}
