@import 'sass/styles';

.account {
  &Container {
    display: flex;
    height: 100%;

    &Authenticated {
      @media (max-width: $maxMobileWidth) {
        display: none;
      }
    }
  }

  &AuthIcon {
    position: relative;
    background: none;
    cursor: pointer;
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;

    > svg {
      width: 30px;
      height: 30px;
      @media (max-width: $maxMobileWidth) {
        width: 24px;
        height: 24px;
      }
    }
  }

  &PlusIcon {
    position: absolute;
    color: #fff;
    font-weight: bold;
    background-color: #1e1e1e;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    height: 12px;
    width: 12px;
    top: 18px;
    right: 0;
    padding-bottom: 1px;

    @media (max-width: $maxMobileWidth) {
      top: 13px;
      right: 0;
      font-size: 10px;
      height: 10.8px;
      width: 10.8px;
      padding-left: 1px;
      padding-bottom: 1px;
    }
  }

  &Icon {
    background: none;
    cursor: pointer;
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;

    &Initials {
      @include flexAlign(center, center);
      border: 2px solid $gunmetal;
      border-radius: 50%;
      font-size: 11px;
      min-width: 30px;
      width: 30px;
      min-height: 30px;
      height: 30px;
      color: $gunmetal;
      @media (max-width: $maxMobileWidth) {
        border: 1px solid $gunmetal;
        min-width: 24px;
        width: 24px;
        min-height: 24px;
        height: 24px;
      }
    }
  }

  &DropDownMenu {
    @media (min-width: $maxMobileWidth) {
      @include flexAlign(center, center);
      position: relative;
      height: 100%;
      cursor: pointer;
    }
  }

  &DropDownContent {
    position: absolute;
    z-index: 12;
    flex-direction: column;
    min-width: 190px;
    justify-content: center;
    align-items: flex-start;
    pointer-events: none;
    display: none;
    border-radius: 8px;
    border: 1px solid $paleGray;
    background: $white;
    top: 50px;
    overflow-y: auto;
    @extend %elevationBottomMedium;
    &Open {
      display: flex;
      pointer-events: initial;
      @media (max-width: $maxMobileWidth) {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100vw;
        height: 100vh;
        border: none;
        border-radius: 0;
        box-shadow: none;
        top: unset;
        right: 0;
        z-index: 0;
      }
    }
    &AuthLink {
      text-decoration: none;
      display: flex;
      padding: 16px;
      width: 100%;
      color: $gunmetal;

      &:hover {
        text-decoration: none;
        font-weight: normal;
        @media (min-width: $minDesktopWidth) {
          color: $darkRed;
        }
      }
      &:focus-visible {
        border: 1px solid blue;
      }
    }
    &Line {
      display: block;
      width: 100%;
      margin: 4px 0;
      border-bottom: 1px solid $paleGray;
      @media (max-width: $maxMobileWidth) {
        margin: 16px 0;
      }
    }
    &Auth {
      :first-child {
        padding-bottom: 0;
      }
    }
    &AccountLink {
      text-decoration: none;
      display: flex;
      padding: 12px 0;
      width: 100%;
      color: $gunmetal;
      white-space: nowrap;
      &:hover {
        text-decoration: none;
        font-weight: normal;
        @media (min-width: $minDesktopWidth) {
          color: $darkRed;
        }
      }
      @media (max-width: $maxMobileWidth) {
        padding: 16px 0;
      }
    }
    &Account {
      padding: 24px;
      @media (max-width: $maxMobileWidth) {
        padding-bottom: 300px;
      }
      &Become {
        padding-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: $maxMobileWidth) {
          justify-content: flex-start;
        }
        > svg {
          padding: 0;
          margin-right: 5px;
        }
      }
      &Logout {
        padding-bottom: 16px;
      }
    }
  }
}
