@import 'sass/styles';

.searchField {
  display: flex;
  width: 325px;
  height: 52px;
  padding: 0px 24px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 6px;
  background: $almostWhite;
  transition: width 0.3s ease;
  border: 1px solid transparent;

  @media (max-width: $maxMobileWidth) {
    width: 92%;
    height: 36px;
    padding: 0px 8px 0px 16px;
  }

  > input {
    background: $almostWhite;
    width: 100%;
    height: 100%;
    @extend %typographyMedium;

    &:focus {
      outline: 0;
    }
  }

  &Focused {
    border: 1px solid $gunmetal;
    @media (min-width: 1000px) {
      width: 400px;
    }

    @media (min-width: 1100px) {
      width: 500px;
    }

    @media (min-width: 1300px) {
      width: 677px;
    }

    @media (max-width: $maxMobileWidth) {
      width: 92%;
    }
  }
}

.searchIconBox {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(0px); /* Fixing layout shift bug in IOS */
}

.searchHeader {
  &Container {
    @include flexAlign(space-between, center);
    gap: 8px;
    width: 100%;
    opacity: 0;

    &Opened {
      animation: fadeIn 1s forwards;
      @keyframes fadeIn {
        to {
          opacity: 1;
        }
      }
    }
  }

  &CloseIconBox {
    width: 100%;
    height: 36px;
    @include flexAlign(center, center);
    transform: translateY(0px); /* Fixing layout shift bug in IOS */

    > svg {
      width: 16px;
      height: 16px;
    }
  }
}

.clearInputBox {
  display: flex;
  cursor: pointer;

  > svg {
    width: 16px;
    height: 16px;
  }
}
