@import 'sass/styles';

.cart {
  display: flex;
  position: relative;
  cursor: pointer;

  &Count {
    position: absolute;
    top: 18px;
    right: 0;
    font-size: 9px;
    line-height: 1.15;
    color: $white;
    font-weight: bold;
    height: 14px;
    width: 14px;
    background-color: $gunmetal;
    border-radius: 50%;
    display: flex;
    @include flexAlign(center, center);

    @media (max-width: $maxMobileWidth) {
      top: 10px;
      right: -3px;
      padding-top: 1px;
    }
  }

  &Icon {
    width: 30px;
    height: 30px;

    @media (max-width: $maxMobileWidth) {
      width: 22.857px;
      height: 24px;
    }
  }
}
