@import 'sass/styles';

.userPreferences {
  &Box {
    @include flexAlign(center, center);
    gap: 4px;
    cursor: pointer;
  }

  &CountryFlag {
    > svg {
      width: 20px;
      height: 20px;
    }
  }

  &Text {
    @include flexAlign(center, center);
    gap: 8px;
    color: $cream;

    > span {
      @extend %typographyTiny;
      color: $cream;
    }
  }

  &Unit {
    text-transform: capitalize;
  }
}
