@import 'sass/styles';

.studioIcon {
  width: 30px;
  height: 30px;
}

.mobileHiddenIconContainer {
  display: flex;

  > a {
    display: flex;
  }

  @media (max-width: $maxMobileWidth) {
    display: none;
  }
}
