@import 'sass/styles';

.mobileSaLogo {
  &Box {
    display: flex;
    gap: 6px;
    align-items: center;

    @media (max-width: $maxMobileWidth) {
      align-items: flex-start;
    }
  }

  &Icon {
    @media (max-width: $maxMobileWidth) {
      width: 136.923px;
      height: 20px;
    }
  }
}
