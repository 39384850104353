@import 'sass/styles';

.header {
  &Container {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (max-width: $maxMobileWidth) {
      display: none;
    }
  }

  &ActionsBox {
    height: 52px;
    @include flexAlign(flex-end, center);

    &Logged {
      width: 220px;
    }
  }

  &LogoActionsContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 24px 56px;
  }

  &SearchActionsContainer {
    display: flex;
    align-items: center;
    gap: 48px;
  }
}
