@import 'sass/styles';

.favoritesLink {
  display: flex;

  > svg {
    width: 30px;
    height: 30px;

    @media (max-width: $maxMobileWidth) {
      width: 24px;
      height: 21.684px;
    }
  }
}
