@import 'sass/styles';

.mailContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  flex: 4;

  @media (max-width: $maxMobileWidth) {
    width: 100%;
    gap: 24px;
  }
}

.footerLogo {
  display: flex;
  gap: 6px;
  align-items: center;

  > svg {
    > path {
      fill: $cream;
    }
  }
}

.joinMailing {
  &Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
  }

  &Box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    color: $cream;
  }

  &Input {
    &Container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      height: 58px;
    }

    &Box {
      display: flex;
      height: 48px;
      width: 100%;
      max-width: 542px;
      padding: 13px 16px;
      justify-content: space-between;
      align-items: center;
      gap: 4px;
      border-radius: 4px;
      border: 1px solid $lightGray;
      background: $cream;

      > input {
        width: 100%;
        height: 100%;
        @extend %typographySmall;
        font-size: 16px;
        background: $cream;

        &:focus {
          font-size: 16px;
          outline: 0;
        }
      }

      &Error {
        border: 1px solid $red;
        background-color: $red20;

        > input {
          background-color: $red20;
        }
      }
    }

    &Button {
      cursor: pointer;

      > svg {
        > path {
          fill: $gunmetal;
        }
      }
    }
  }
}

.socialMedia {
  &Wrapper {
    @include flexAlign(center, center);
    flex-direction: row;
    gap: 24px;

    @media (max-width: 375px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
  }

  &Container {
    @include flexAlign(center, center);
    flex-direction: row;
    gap: 24px;
  }
}

.mobileSaLogoTypography {
  > g {
    > path {
      fill: $cream;
    }
  }
}

.emailError {
  color: $red;
}

.emailSubscribeMessage {
  @extend %typographyH5;
  color: $cream;
  height: 48px;
}
