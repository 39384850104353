@import 'sass/styles';

.accordionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.accordionItem {
  &Wrapper {
    width: 100%;
  }

  &Divider {
    margin-top: 16px;
  }
}

.itemBox {
  display: flex;
  flex-direction: row;
  padding-right: 8px;
  justify-content: space-between;
  align-items: center;
  justify-self: stretch;
}

.itemBoxSpan {
  @include typographyH6;

  @media (min-width: $maxMobileWidth) {
    text-transform: uppercase;
  }
}

.contentBox {
  overflow: hidden;
  max-height: 0;
  transition: all 0.4s ease-in-out;

  &Visible {
    max-height: 4000px;
    margin-top: 16px;
  }
}

.expandCollapseIcon {
  transition: transform 0.4s ease-in-out;

  > path {
    fill: $gunmetal;
  }
}

.toggleInput {
  display: none;
}

.toggleInput:checked + label .expandCollapseIcon {
  transform: rotate(45deg);
}
