@import 'sass/styles';

.topHeader {
  &MenuIconBox {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 4px;

    > div {
      @include flexAlign(center, center);
      width: 32px;
    }
  }
  &MenuAccount {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    width: 100%;
  }
}

.closeIcon {
  width: 17px;
  height: 17px;
}

.user {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  &Icon {
    width: 24px;
    height: 24px;
  }

  &Name {
    text-align: center;
  }
}

.iconsWrapper {
  display: flex;
  gap: 12px;
}
