@import 'sass/styles';

.mobileCategories {
  &Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px;
    height: 100vh;
    background-color: $white;
    overflow-y: auto;
    padding-bottom: 220px;

    @media (max-width: $maxMobileWidth) {
      padding-bottom: 300px;
    }

    position: fixed;
    left: 0;
  }

  &Category {
    &Box {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &Arrow {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 8px;
    }

    &Account {
      display: flex;
      gap: 4px;
      overflow-wrap: anywhere;

      &Icon {
        min-width: 20px;
        width: 20px;
        min-height: 20px;
        height: 20px;
      }
    }
  }
}

.mobileCategoriesOptions {
  &Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100vh;
    background-color: #ffffff;
    overflow-y: auto;
    padding-bottom: 220px;

    @media (max-width: $maxMobileWidth) {
      padding-bottom: 300px;
    }
  }

  &ArrowIcon {
    width: 24px;
    height: 24px;
    transform: rotate(180deg);
    transform-origin: center;
  }

  &TitleBox {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 12px 8px 12px;
  }

  &ViewAll {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px;
  }

  &Container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &Box {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 16px;

    &NoTitle {
      margin-top: -8px;
    }
  }

  &Subcategory {
    &Box {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 0px 24px;

      &NoTitle {
        padding: 0px 0px;
      }
    }
  }

  &ImageCta {
    display: inline-flex;
    gap: 24px;
    padding: 0 16px;

    > img {
      width: 145px;
      object-fit: cover;
    }

    &TextBox {
      @include flexAlign(center, center);
      flex-direction: column;
      gap: 8px;
    }
  }
}

.category {
  &TitleBox {
    width: 100%;
  }
}

.featuredCategoryBox {
  @include flexAlign(center, center);
  flex-direction: column;
  width: 100%;
  gap: 8px;
  text-transform: uppercase;

  &View {
    border-bottom: 1px solid #737373;
  }
}

.userPreferences {
  &Box {
    @include flexAlign(center, center);
    gap: 4px;
  }

  &Text {
    @include flexAlign(center, center);
    gap: 4px;

    > span {
      @extend %typographyH5;
    }
  }

  &Unit {
    text-transform: capitalize;
  }

  &CountryFlag {
    width: 24px;
    height: 24px;

    > div {
      width: 24px;
      height: 24px;

      > svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.userIcon {
  &Box {
    position: relative;
  }

  &Plus {
    position: absolute;
    color: #fff;
    font-weight: bold;
    background-color: #1e1e1e;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 13px;
    right: 0;
    font-size: 10px;
    height: 10.8px;
    width: 10.8px;
    padding-left: 1px;
    padding-bottom: 1px;
  }
}

.mobileFeatureSection {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
