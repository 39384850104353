@import 'sass/styles';

.linksContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 7;

  @media (max-width: $maxMobileWidth) {
    display: none;
  }

  @media (min-width: 1440px) {
    flex: 6;
  }

  @media (min-width: 1792px) {
    flex: 5;
  }
}

.linksBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  > span,
  > svg {
    color: $cream;
  }

  > a {
    color: $cream;
    text-decoration: none;
    display: flex;
    gap: 8px;

    &:hover {
      @extend %typographyXSmall;
      color: $cream;
      text-decoration: underline;
      > svg {
        color: $cream;
        text-decoration: underline;
      }
    }

    &:visted {
      @extend %typographyXSmall;
      color: $cream;
      font-weight: 400;
    }

    &:link {
      color: $cream;
      @extend %typographyXSmall;
      font-weight: 400;
    }

    @media (max-width: $maxMobileWidth) {
      &:hover {
        text-decoration: none;
      }
    }
  }

  @media (max-width: $maxMobileWidth) {
    > span {
      text-transform: uppercase;
    }
  }
}

.mobileLinksContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.customAccordionContainer {
  > span {
    color: $cream;
    text-transform: uppercase;
  }

  > svg {
    > path {
      fill: $cream;
    }
  }
}
