@import 'sass/styles';

.mobileHeader {
  &Container {
    display: none;
    flex-direction: column;

    @media (max-width: $maxMobileWidth) {
      display: flex;
    }
  }

  &Sticky {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--mobile-header-height);
    gap: 16px;
    padding: 16px;
    background-color: $white;

    &Border {
      border-bottom: 1px solid $paleGray;
    }
  }

  &MenuContent {
    position: fixed;
    top: var(--dynamic-top-menu);
    display: flex;
    width: 100%;
  }
}
