@import 'sass/styles';

.categoriesCta {
  &Container {
    display: flex;
    padding: 0px 56px;
    gap: 24px;
    position: relative;

    @media (max-width: 1032px) {
      gap: 20px;
    }
  }

  &Text {
    border-bottom: 1px solid #ffffff;
    padding-bottom: 8px;
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid #da3b1f;
    }

    > a:link {
      @extend %typographyH5;
    }
  }
}

.categoriesHover {
  &Container {
    position: absolute;
    z-index: 100;
    background-color: #ffffff;
    width: 100%;
    display: none; // this controls the hover on categories to show div with subcategories
    padding: 48px 56px;
    min-height: 345px;

    &Hover {
      @include flexAlign(flex-start, flex-start);
      @extend %elevationBottomXS;
      gap: 10%;
    }
  }

  &Options {
    display: flex;
    gap: 64px;
    white-space: nowrap;

    > a:hover {
      color: $darkRed;
    }

    &Subcategories {
      display: none;
      flex-direction: column;
      gap: 16px;
      width: 120px;

      > a:hover {
        color: $darkRed;
      }

      > a:link {
        @extend %typographyXSmall;
      }

      &Selected {
        display: flex;
      }
    }
  }

  &Image {
    &Container {
      display: none;
      flex-direction: column;
      gap: 11px;

      &Selected {
        display: flex;
      }

      > img {
        width: 234px;
        height: 145px;
        object-fit: cover;
      }
    }

    &Text {
      display: inline-block;
      border-bottom: 1px solid #737373;
      text-transform: uppercase;
      &:hover {
        color: $darkRed;
        border-bottom: 1px solid $darkRed;
      }
    }
  }
}

.categoriesCtaWrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  border-bottom: 1px solid #d8d8d8;

  &HoverBox {
    position: relative;
  }
}

.backgroundOverlay {
  position: absolute;
  width: 100%;
  height: var(--dynamic-body-height);
  background-color: rgba(33, 33, 33, 0.85);
  z-index: 10;
}

.featureSection {
  display: flex;
  flex-direction: column;
  gap: 28px;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
}
